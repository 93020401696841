import { customAlphabet } from 'nanoid';
import PartySocket from "partysocket";
import dompurify from 'dompurify';
import MarkdownIt from 'markdown-it';
import './constat.js';
import z from "zod";

const ChatMessage = z.object({
    type: z.literal('chat'),
    message: z.string(),
    username: z.string(),
    timestamp: z.date({coerce: true}),
});

const UpdateChatMessage = z.object({
    type: z.literal('messages'),
    messages: z.array(ChatMessage),
});

const ErrorMessage = z.object({
    type: z.literal('error'),
    message: z.string(),
});
const JoinMessage = z.object({
    type: z.literal('join'),
    username: z.string(),
    timestamp: z.date({coerce: true}),
});
const LeaveMessage = z.object({
    type: z.literal('leave'),
    username: z.string(),
    timestamp: z.date({coerce: true}),
});
const ServerMessage = z.union([
    ErrorMessage, JoinMessage, LeaveMessage, UpdateChatMessage, ChatMessage
]);

function stamp() {
    return new Date().toISOString();
}
function humanize(date) {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return date.toLocaleDateString()+' '+date.toLocaleTimeString();
}

const alphabet = '6789BCDFGHJKLMNPQRTWbcdfghjkmnpqrtwz';
const nanoid = customAlphabet(alphabet, 8);
const md = new MarkdownIt();

const socket = new PartySocket({
    host: location.host,
    room: 'chat',
    id: nanoid()
});
document.querySelector('connection-status').link(socket);

const log_message = (msg) => {
    const el = document.createElement('div');
    msg = Object.assign(msg, { message: dompurify.sanitize(md.render(msg.message)) });  
    msg = `<time datetime="${msg.timestamp}">${humanize(msg.timestamp)}</time> - <span class="user ${msg.username}">${msg.username}</span>: ${msg.message}`;
    el.innerHTML = msg;
    el.className = 'message';
    chat.appendChild(el);
}
const sys_message = (msg) => {
    const el = document.createElement('div');
    el.textContent = msg;
    el.className = 'message system';
    chat.appendChild(el);
}


const chat = document.getElementById('chat');
const form = document.getElementById('chat-form');

form.addEventListener('submit', (event) => {
    event.preventDefault();
    const input = form.querySelector('input');
    let msg = { type: 'chat', message: input.value, username: socket.id, timestamp: stamp() };
    socket.send(JSON.stringify(msg));
    input.value = '';
    log_message(msg);
});

socket.addEventListener('open', () => {
    document.getElementById('chat-id').textContent = socket.id;
    console.log('Connected to chat server');
});

socket.addEventListener('message', (message) => {
    console.log('Received message:', message.data);
    const result = ServerMessage.safeParse(JSON.parse(message.data));
    if (!result.success) {
        console.error('Invalid message:', message.data);
        return;
    }
    const { type, ...data } = result.data;

    if (type === 'chat') {
        log_message(data);
    }
});